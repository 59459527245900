.App {
  text-align: center;
  padding: 60px 60px;
  height: 100vh;
  min-height: 600px;
  position: relative;
}

.App:before {
  content: '';
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
}

header {
  padding: 50px 20px 50px;
}

.box {
  background: #ff6262;
  width: 800px;
  max-width: 90%;
  margin: 0 auto 40px;
  padding: 65px 10px 70px;
  border-radius: 8px;
}

.box p {
  margin: 0 0 20px;
}

input {
  width: 80%;
  border-radius: 6px;
  padding: 15px 25px;
  background: #eee;
  border: 1px solid #777;
  font-size: 24px;
}

input.spin {
  background-image: url('./images/spinner.gif');
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: 98% center;
}

.result p {
  font-size: 50px;
  font-style: italic;
  font-weight: 400;
  margin: 0;
}

.result h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.error {
  background: #fff;
  color: #ff3456;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 8px;
  width: auto;
  display: inline-block;
  max-width: 600px;
  width: 80%;
}

.mw {
  position: fixed;
  z-index:2;
  width: 50px;
  bottom: 5px;
  right: 5px;
}

.result-sentence:first-letter {
  text-transform: capitalize;
  display: block;
}

.contact {
  position: fixed;
  bottom: 5px;
  right: 10px;
  z-index: 1;
}

.contact p {
  color: #fff;
  margin: 0;
  font-size: 16px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: 400;
}

.contact a {
  color: #ff6262;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

@media(max-width: 640px) {
  .App {
    padding: 20px 20px;
  }

  header {
    padding: 20px 20px 25px;
  }

  .box {
    padding: 20px 10px 30px;
  }
  input {
    font-size: 18px;
    width: 75%;
  }
  .result p {
    font-size: 30px;
  }
}