body {
  margin: 0;
  font-family: "Helvetica Neue", "Helvetica", "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(./images/smart-books.jpg);
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  position: relative;
  background-attachment: fixed;
  color: #fff;
  box-sizing: border-box;
}

h1, h2, h3 {
  font-weight: 800;
}

p {
  font-size: 21px;
  font-weight: 600;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
